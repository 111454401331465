import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IDraft,
  ISentDoc,
  IMeta,
  IInboxDoc,
  IInboxDocDetailed,
  IGlobalSearchDoc,
} from "types";

import {
  getDrafts,
  deleteDrafts,
  getSentDocs,
  getInboxDocs,
  getInboxDoc,
} from "./thunks";

export type SigningsState = {
  search: string;
  isMovingItemsModal: boolean;

  isDeleteDraftItemsModal: boolean;
  selectedDraftItems: any[];
  drafts: IDraft[];
  draftsToDelete: string[];

  sentDocs: ISentDoc[];
  sentMeta: IMeta | null;
  selectedSentItems: ISentDoc[];
  isDeleteSentItemsModal: boolean;
  isSentCancelRequestModal: boolean;
  sentToDelete: string[];
  sentToMove: string[];
  sentToCancelRequest: string[];

  inboxDocs: IInboxDoc[];
  inboxDoc: IInboxDocDetailed | null;
  inboxMeta: IMeta | null;
  selectedInboxItems: IInboxDoc[];
  isDeleteInboxItemsModal: boolean;
  isInboxRejectRequestModal: boolean;
  inboxToDelete: string[];
  inboxToMove: string[];
  inboxToRejectRequest: string[];

  isDetailedViewMenu: boolean;
};

const initialState: SigningsState = {
  search: "",
  isMovingItemsModal: false,

  selectedDraftItems: [],
  isDeleteDraftItemsModal: false,
  drafts: [],
  draftsToDelete: [],

  sentDocs: [],
  sentMeta: null,
  selectedSentItems: [],
  isDeleteSentItemsModal: false,
  isSentCancelRequestModal: false,
  sentToDelete: [],
  sentToMove: [],
  sentToCancelRequest: [],

  inboxDocs: [],
  inboxDoc: null,
  inboxMeta: null,
  selectedInboxItems: [],
  isDeleteInboxItemsModal: false,
  isInboxRejectRequestModal: false,
  inboxToDelete: [],
  inboxToMove: [],
  inboxToRejectRequest: [],

  isDetailedViewMenu: false,
};

const signingsSlice = createSlice({
  name: "signings",
  initialState,
  reducers: {
    setSearch(state, { payload }: PayloadAction<string>) {
      state.search = payload;
    },
    setIsMovingItemsModal(state, { payload }: PayloadAction<boolean>) {
      state.isMovingItemsModal = payload;
    },
    setIsDetailedViewMenu(state, { payload }: PayloadAction<boolean>) {
      state.isDetailedViewMenu = payload;
    },

    setSelectedDraftItems(
      state,
      { payload }: PayloadAction<IDraft[] | IGlobalSearchDoc[]>,
    ) {
      state.selectedDraftItems = payload;
    },
    setIsDeleteDraftItemsModal(state, { payload }: PayloadAction<boolean>) {
      state.isDeleteDraftItemsModal = payload;
    },
    setDraftsToDelete(state, { payload }: PayloadAction<string[]>) {
      state.draftsToDelete = payload;
    },

    setSelectedSentItems(state, { payload }: PayloadAction<ISentDoc[]>) {
      state.selectedSentItems = payload;
    },
    setIsDeleteSentItemsModal(state, { payload }: PayloadAction<boolean>) {
      state.isDeleteSentItemsModal = payload;
    },
    setIsSentCancelRequestModal(state, { payload }: PayloadAction<boolean>) {
      state.isSentCancelRequestModal = payload;
    },
    setSentToDelete(state, { payload }: PayloadAction<string[]>) {
      state.sentToDelete = payload;
    },
    setSentToMove(state, { payload }: PayloadAction<string[]>) {
      state.sentToMove = payload;
    },
    setSentToCancelRequest(state, { payload }: PayloadAction<string[]>) {
      state.sentToCancelRequest = payload;
    },

    setSelectedInboxItems(state, { payload }: PayloadAction<IInboxDoc[]>) {
      state.selectedInboxItems = payload;
    },
    setIsDeleteInboxItemsModal(state, { payload }: PayloadAction<boolean>) {
      state.isDeleteInboxItemsModal = payload;
    },
    setIsInboxRejectRequestModal(state, { payload }: PayloadAction<boolean>) {
      state.isInboxRejectRequestModal = payload;
    },
    setInboxToDelete(state, { payload }: PayloadAction<string[]>) {
      state.inboxToDelete = payload;
    },
    setInboxToMove(state, { payload }: PayloadAction<string[]>) {
      state.inboxToMove = payload;
    },
    setInboxToRejectRequest(state, { payload }: PayloadAction<string[]>) {
      state.inboxToRejectRequest = payload;
    },
    setInboxDoc(state, { payload }: PayloadAction<IInboxDocDetailed | null>) {
      state.inboxDoc = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDrafts.fulfilled, (state, action) => {
      state.drafts = action.payload?.items || [];
    });
    builder.addCase(deleteDrafts.fulfilled, (state, action) => {
      state.drafts = state.drafts.filter((el) =>
        action.payload?.message === "api.messages.sharedDocuments.draftsDeleted"
          ? !state.draftsToDelete.includes(el.id)
          : true,
      );
      state.draftsToDelete = [];
    });
    builder.addCase(getSentDocs.fulfilled, (state, action) => {
      state.sentDocs = action.payload?.items || [];
      state.sentMeta = action.payload?.meta || null;
    });
    builder.addCase(getInboxDocs.fulfilled, (state, action) => {
      state.inboxDocs = action.payload?.items || [];
      state.inboxMeta = action.payload?.meta || null;
    });
    builder.addCase(getInboxDoc.fulfilled, (state, action) => {
      state.inboxDoc = action.payload || null;
    });
  },
});

export const {
  setSearch,
  setIsMovingItemsModal,
  setIsDetailedViewMenu,

  setIsDeleteDraftItemsModal,
  setSelectedDraftItems,
  setDraftsToDelete,

  setSelectedSentItems,
  setIsDeleteSentItemsModal,
  setSentToDelete,
  setSentToMove,
  setIsSentCancelRequestModal,
  setSentToCancelRequest,

  setSelectedInboxItems,
  setIsDeleteInboxItemsModal,
  setInboxToDelete,
  setInboxToMove,
  setIsInboxRejectRequestModal,
  setInboxToRejectRequest,
  setInboxDoc,
} = signingsSlice.actions;

export default signingsSlice.reducer;
