import { FC, useEffect, useState, useCallback } from "react";
import { Instance } from "pspdfkit";
import { useTranslation } from "react-i18next";

import { Button } from "components/UI";

import { useInstance } from "hooks";
import { cs } from "utils";

import styles from "./styles.module.scss";

type GoNextButtonProps = {
  stateInstance: Instance | null;
  statePSPDFKit: any;
  isFooter?: boolean;
  stateChanged?: boolean;
  // fromTop?: number;
};

export const GoNextButton: FC<GoNextButtonProps> = ({
  stateInstance,
  statePSPDFKit,
  isFooter,
  stateChanged,
  // fromTop = 0,
}) => {
  const { t } = useTranslation("RecipientSigningFooter");
  const { getUnsignedWidgets, getSignedWidgets, getTotalWidgetsCount } =
    useInstance();
  // const [unsignedWidgetIndex, setUnsignedWidgetIndex] = useState<number>(0);
  const [totalWidgets, setTotalWidgets] = useState<number>(0);
  const [totalSigned, setTotalSigned] = useState<number>(0);
  const [fromTop, setFromTop] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isGoNext, setGoNext] = useState<boolean>(false);

  const handleGoNext = useCallback(async () => {
    if (stateInstance) {
      const allWidgets = await getUnsignedWidgets({
        instance: stateInstance,
        PSPDFKit: statePSPDFKit,
      });
      const allFormFieldValues = stateInstance.getFormFieldValues();
      const unsignedWidgets: any[] = [];
      await Promise.all(
        allWidgets.map(async (el) => {
          const isOverLapped =
            await stateInstance.getOverlappingAnnotations(el);
          const formFieldName = allFormFieldValues[el.formFieldName];
          if (
            isOverLapped.size === 0 && el.formFieldName
              ? (Array.isArray(formFieldName) && formFieldName[0] === "Off") ||
                allFormFieldValues[el.formFieldName] === "" ||
                el.formFieldName?.startsWith("SIGNATURE")
              : true
          ) {
            unsignedWidgets.push(el);
          }
        }),
      );

      if (unsignedWidgets[0]) {
        if (isGoNext || isFooter) {
          stateInstance.jumpAndZoomToRect(
            unsignedWidgets[0].pageIndex,
            unsignedWidgets[0].boundingBox,
          );
          stateInstance.setViewState((state) => state.set("zoom", 1.25));
          if (unsignedWidgets[0]?.boundingBox) {
            setFromTop(
              unsignedWidgets[0].boundingBox.top +
                unsignedWidgets[0].boundingBox.height / 2 +
                40 || 0,
            );
            setCurrentPage(unsignedWidgets[0].pageIndex);
          }

          // setUnsignedWidgetIndex((prevState) =>
          //   prevState < unsignedWidgets.length - 1 ? prevState + 1 : 0,
          // );
          setGoNext(false);
        }
        // else {
        //   signSignatureField({
        //     instance: stateInstance,
        //     PSPDFKit: statePSPDFKit,
        //     annotation:
        //       unsignedWidgets[
        //         unsignedWidgetIndex - 1 < 0
        //           ? unsignedWidgets.length - 1
        //           : unsignedWidgetIndex - 1
        //       ],
        //   });
        //   setGoNext(true);
        // }
      }
    }
  }, [
    getUnsignedWidgets,
    isGoNext,
    // signSignatureField,
    stateInstance,
    statePSPDFKit,
    // unsignedWidgetIndex,
    isFooter,
  ]);

  const initTotalWidgets = useCallback(async () => {
    if (stateInstance) {
      const totalWidgetsCount = await getTotalWidgetsCount({
        instance: stateInstance,
        PSPDFKit: statePSPDFKit,
      });

      setTotalWidgets(totalWidgetsCount);
    }
  }, [getTotalWidgetsCount, stateInstance, statePSPDFKit]);

  const initTotalSigned = useCallback(async () => {
    if (stateInstance) {
      const signedWidgets = await getSignedWidgets({
        instance: stateInstance,
      });

      setTotalSigned(signedWidgets || 0);
    }
  }, [getSignedWidgets, stateInstance]);

  useEffect(() => {
    initTotalWidgets();
  }, [initTotalWidgets]);

  useEffect(() => {
    initTotalSigned();
  }, [initTotalSigned, stateChanged]);

  useEffect(() => {
    setGoNext(true);
  }, [totalSigned]);

  useEffect(() => {
    const zoomInstance =
      stateInstance?.contentDocument.querySelectorAll(".PSPDFKit-Spread");
    const oldPointer =
      stateInstance?.contentDocument.querySelector<HTMLElement>(
        "#documentWidgetPointer",
      );
    oldPointer && oldPointer.parentNode?.removeChild(oldPointer);
    const pageInstance = zoomInstance && zoomInstance[currentPage];
    if (pageInstance) {
      const buttonEl = document.createElement("div");
      buttonEl.id = "documentWidgetPointer";
      buttonEl.style.position = "absolute";
      buttonEl.style.left = "-100px";
      buttonEl.style.top = `0px`;
      buttonEl.style.width = "100px";
      buttonEl.style.height = "40px";
      buttonEl.style.backgroundColor = "black";
      buttonEl.style.borderRadius = "5px 0 0 5px";
      buttonEl.style.color = "white";
      buttonEl.style.zIndex = "102";
      buttonEl.style.fontSize = "16px";
      buttonEl.style.textAlign = "center";
      buttonEl.style.display = "flex";
      buttonEl.style.justifyContent = "center";
      buttonEl.style.alignItems = "center";
      buttonEl.innerHTML = "Go next";
      buttonEl.style.pointerEvents = "all";
      buttonEl.addEventListener("pointerdown", handleGoNext, {
        capture: true,
      });
      pageInstance.appendChild(buttonEl);
    }
  }, [stateInstance, currentPage, handleGoNext, totalSigned, totalWidgets]);

  useEffect(() => {
    const pointer = stateInstance?.contentDocument.querySelector<HTMLElement>(
      "#documentWidgetPointer",
    );
    if (pointer) {
      pointer.style.top = `${fromTop}px`;
      pointer.innerHTML = isGoNext
        ? t("goToNext")
        : `${t("sign")} (${totalSigned}/${totalWidgets})`;
    }
    if (pointer && totalSigned > 0 && totalSigned === totalWidgets) {
      pointer.parentNode?.removeChild(pointer);
    }
  }, [fromTop, stateInstance, isGoNext, t, totalSigned, totalWidgets]);

  return (
    <div
      className={cs([styles.GoNextButton, isFooter && styles.relative])}
      // style={{ top: `100px` }}
    >
      <div className={styles.wrap}>
        {isFooter && (
          <Button
            title={t("goToNext")}
            // title={
            //   isGoNext || isFooter
            //     ? t("goToNext")
            //     : `${t("sign")} (${totalSigned}/${totalUnsigned})`
            // }
            onClick={handleGoNext}
            variant="primary"
          />
        )}
        {/* {!isFooter && (
          <p className={styles.textInfo}>
            Signed {`(${totalSigned}/${totalUnsigned})`}
          </p>
        )} */}
      </div>
    </div>
  );
};
