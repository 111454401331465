import { useState, FC } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector, signings } from "store";

import { Icon, Modal } from "components/UI";
import { ActionsHistoryModal } from "components";

import { cs } from "utils";
import { SIGNING_INBOX_STATUSES } from "types";

import styles from "./styles.module.scss";

export const RecipientsWidget: FC = () => {
  const { t } = useTranslation("RequestSigning", {
    keyPrefix: "RecipientsWidget",
  });
  const { inboxDoc } = useAppSelector(signings);
  const [isMinimized, setIsMinimized] = useState<boolean>(false);
  const [isResizer, setIsResizer] = useState<boolean>(false);
  const [isActionsHistory, setIsActionsHistory] = useState<boolean>(false);

  if (inboxDoc?.recipients?.length === 0) {
    return <></>;
  }

  return (
    <nav
      className={cs([
        styles.RecipientsWidget,
        isMinimized && styles.minimized,
        inboxDoc?.status === "REJECTED" && styles.rejected,
      ])}
    >
      <Modal isShowed={isActionsHistory}>
        <ActionsHistoryModal onClose={() => setIsActionsHistory(false)} />
      </Modal>
      <div
        className={cs([styles.resizer, isResizer && styles.visible])}
        onClick={() => setIsMinimized((prevState) => !prevState)}
        onMouseEnter={() => setIsResizer(true)}
        onMouseLeave={() => setIsResizer(false)}
      >
        <Icon
          name="chevron-left-empty"
          size={20}
          className={cs([styles.arrowIcon, isMinimized && styles.rotated])}
        />
      </div>
      <div className={styles.recipients}>
        {!isMinimized && <h2 className={styles.title}>{t("recipients")}:</h2>}
        <ul
          className={cs([
            styles.recipientsList,
            isMinimized && styles.minimized,
          ])}
        >
          {inboxDoc?.recipients?.map((item) => (
            <li className={styles.recipient} key={item.email}>
              <div
                className={cs([styles.card, isMinimized && styles.minimized])}
                style={{
                  backgroundColor: item.color,
                }}
              >
                <div className={styles.name}>{item.name}</div>
                {!isMinimized && (
                  <div className={styles.email} title={item.email}>
                    {item.email}
                  </div>
                )}
              </div>
              <div className={cs([styles.status, styles[item.status]])}>
                {SIGNING_INBOX_STATUSES[item.status]}
              </div>
            </li>
          ))}
        </ul>
        <div
          className={styles.actionsHistory}
          onClick={() => setIsActionsHistory(true)}
        >
          <Icon name="info" size={14} />
          {!isMinimized && (
            <div className={styles.actionsHistoryText}>
              {t("actionsHistory")}
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};
