import { FC, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Annotation, Instance } from "pspdfkit";

import { useAppSelector, signings } from "store";

import { SharedDocuments } from "api";

import { Button } from "components/UI";

import { PATHES } from "constants/pathes";
import { toastSuccess, cs } from "utils";

import { GoNextButton } from "../GoNextButton";
import styles from "./styles.module.scss";

type RecipientSigningFooterProps = {
  stateChanged: boolean;
  stateInstance: Instance | null;
  statePSPDFKit: any;
};

export const RecipientSigningFooter: FC<RecipientSigningFooterProps> = ({
  stateChanged,
  stateInstance,
  statePSPDFKit,
}) => {
  const { t } = useTranslation("RecipientSigningFooter");
  const [isFooter, setIsFooter] = useState<boolean>(false);
  const [isFullyCompleted, setIsFullyCompleted] = useState<boolean>(false);
  const { inboxDoc } = useAppSelector(signings);
  const navigate = useNavigate();

  const checkIfFilledWithSignatures = useCallback(async () => {
    if (stateInstance && statePSPDFKit) {
      const totalPages = stateInstance?.totalPageCount;

      let widgetsSignaturesToSignCount = 0;
      let widgetsInitialsToSignCount = 0;
      let widgetsDateCount = 0;
      let widgetsCheckboxCount = 0;
      let widgetsTextCount = 0;

      let signaturesCount = 0;
      let initialsCount = 0;

      const pageIndexes = Array.from(Array(totalPages).keys());
      const allFormFieldValues = stateInstance.getFormFieldValues();

      try {
        if (pageIndexes.length > 0 && statePSPDFKit && stateInstance) {
          await Promise.all(
            pageIndexes.map(async (page) => {
              try {
                const allAnnotations = await stateInstance.getAnnotations(page);

                const widgetsSignaturesToSign = allAnnotations.filter(
                  (el: Annotation) =>
                    el.formFieldName?.startsWith("SIGNATURE_WIDGET"),
                );
                const widgetsInitialsToSign = allAnnotations.filter(
                  (el: Annotation) =>
                    el.formFieldName?.startsWith("SIGNATURE_INITIALS"),
                );
                const widgetsDate = allAnnotations.filter(
                  (el: Annotation) =>
                    el.formFieldName?.startsWith("DATE_WIDGET"),
                );
                const widgetsCheckbox = allAnnotations.filter(
                  (el: Annotation) =>
                    el.formFieldName?.startsWith("CHECKBOX_WIDGET"),
                );
                const widgetsText = allAnnotations.filter(
                  (el: Annotation) =>
                    el.formFieldName?.startsWith("TEXT_WIDGET"),
                );

                const signatures = allAnnotations.filter(
                  (el: Annotation) => el.isSignature && !el.customData,
                );
                const initials = allAnnotations.filter(
                  (el: Annotation) => el.isSignature && el.customData,
                );

                // Increment counts
                widgetsSignaturesToSignCount += widgetsSignaturesToSign.size;
                widgetsInitialsToSignCount += widgetsInitialsToSign.size;
                widgetsDateCount += widgetsDate.size;
                widgetsCheckboxCount += widgetsCheckbox.size;
                widgetsTextCount += widgetsText.size;

                signaturesCount += signatures.size;
                initialsCount += initials.size;
              } catch (error) {
                console.log("error:", error);
              }
            }),
          );
        }
      } catch (error) {
        console.log("error:", error);
      }
      const isAllSignaturesFilled =
        signaturesCount > 0 && signaturesCount === widgetsSignaturesToSignCount;
      const isAllInitialsFilled =
        initialsCount > 0 ? initialsCount === widgetsInitialsToSignCount : true;
      const isAllDateFilled =
        widgetsDateCount > 0
          ? Object?.entries(allFormFieldValues)
              ?.filter((el: any) => el[0].startsWith("DATE_WIDGET"))
              .filter((el) => el[1] && el[1][0] !== "")?.length ===
            widgetsDateCount
          : true;
      const isAllCheckboxFilled =
        widgetsCheckboxCount > 0
          ? Object?.entries(allFormFieldValues)
              ?.filter((el: any) => el[0].startsWith("CHECKBOX_WIDGET"))
              .filter((el) => el[1] && el[1][0] !== "Off")?.length ===
            widgetsCheckboxCount
          : true;
      const allTextWidgets = Object?.entries(allFormFieldValues)?.filter(
        (el: any) => el[0].startsWith("TEXT_WIDGET"),
      );
      const isAllTextFilled =
        widgetsTextCount > 0
          ? allTextWidgets?.filter((el: any) => el[1] && el[1][0] !== "")
              ?.length === widgetsTextCount
          : true;
      if (
        isAllSignaturesFilled &&
        isAllInitialsFilled &&
        isAllDateFilled &&
        isAllCheckboxFilled
      ) {
        setIsFooter(true);
        setIsFullyCompleted(allTextWidgets.length > 0 ? isAllTextFilled : true);
      } else {
        setIsFooter(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateInstance, statePSPDFKit, stateChanged]);

  const handleFinish = async () => {
    const savedInstant = localStorage.getItem("instantJSON");
    const parsedInstant = savedInstant && JSON.parse(savedInstant);
    if (isFooter && parsedInstant && inboxDoc?.id) {
      const res = await SharedDocuments.signInboxDoc(inboxDoc.id, {
        instantJson: parsedInstant,
      });
      if (res) {
        toastSuccess(t("toastSuccess"));
        navigate(PATHES.INBOX);
      }
    }
  };

  useEffect(() => {
    checkIfFilledWithSignatures();
  }, [checkIfFilledWithSignatures]);

  return (
    <>
      {isFooter && (
        <footer
          className={cs([
            styles.RecipientSigningFooter,
            isFullyCompleted && styles.completed,
          ])}
        >
          {isFullyCompleted ? (
            <>
              <div className={styles.completedText}>
                <p className={styles.text}>{t("completedText")}</p>
                <p className={styles.additionalText}>
                  {t("additionalComletedText")}
                </p>
              </div>
              <Button
                title={t("submitButtonTitle")}
                onClick={handleFinish}
                variant="primary"
                size="lg"
                className={styles.singleButton}
              />
            </>
          ) : (
            <>
              <p className={styles.text}>{t("requiredText")}</p>
              <div className={styles.buttonsWrap}>
                <GoNextButton
                  stateInstance={stateInstance}
                  statePSPDFKit={statePSPDFKit}
                  isFooter
                />
                <Button
                  title={t("submitButtonTitle")}
                  onClick={handleFinish}
                  variant="primary"
                  size="lg"
                  className={styles.pairButton}
                />
              </div>
            </>
          )}
        </footer>
      )}
    </>
  );
};
