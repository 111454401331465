import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, signings, folders } from "store";
import {
  setIsDeleteInboxItemsModal,
  setIsInboxRejectRequestModal,
  setIsMovingItemsModal,
  setInboxToDelete,
  setInboxToRejectRequest,
} from "store/signings";
import {
  setDestinationFolder,
  setCurrentContextFolder,
  setRenameModalItem,
  setSoftDeletedItems,
} from "store/folders";
import { deleteInbox } from "store/signings/thunks";

import {
  AreYouSureModal,
  MovingItemsModal,
  CreateFolderModal,
  RenameModal,
  RejectSigningModal,
} from "components";
import { Modal } from "components/UI";
import { toastSuccess, toastUndo } from "utils";

import styles from "./styles.module.scss";

type SigningsInboxTableModalsProps = {
  refreshState: () => void;
};

export const SigningsInboxTableModals: FC<SigningsInboxTableModalsProps> = ({
  refreshState,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("Table", {
    keyPrefix: "SigningsInbox.Modals",
  });
  const {
    isDeleteInboxItemsModal,
    isInboxRejectRequestModal,
    isMovingItemsModal,
    selectedInboxItems,
    inboxToDelete,
    inboxDocs,
    inboxToRejectRequest,
  } = useAppSelector(signings);
  const { renameModalItem } = useAppSelector(folders);
  const isSomeNeedToSign = selectedInboxItems.some(
    (el) => el.status === "NEED_TO_SIGN",
  );
  const [isCreateModal, setIsCreateModal] = useState<boolean>(false);

  const handleCloseAreYouSureMoveToBin = () => {
    dispatch(setIsDeleteInboxItemsModal(false));
  };

  const handleConfirmAreYouSureMoveToBin = () => {
    const id = inboxToDelete[inboxToDelete.length - 1];
    let text = t("movingFileToTrashBinToastText");
    let textUndo = t("movingFileToTrashBinToastTextUndo");

    if (inboxToDelete.length > 1) {
      text = t("movingPluralToTrashBinToastText");
      textUndo = t("movingPluralToTrashBinToastTextUndo");
    }

    const filteredInboxItems = inboxToDelete.filter(
      (el) =>
        !selectedInboxItems
          .filter((item) => item.status === "NEED_TO_SIGN")
          .some((item) => item.id === el),
    );

    dispatch(setSoftDeletedItems([...filteredInboxItems]));

    let undo = false;

    toastUndo({
      id,
      text,
      textUndo,
      onUndo: () => {
        dispatch(setSoftDeletedItems([]));
        dispatch(setInboxToDelete([]));
        undo = true;
      },
      onSubmit: async () => {
        if (!undo) {
          await Promise.all(
            filteredInboxItems.map((el) => dispatch(deleteInbox(el))),
          );
          refreshState();
        }
      },
    });

    handleCloseAreYouSureMoveToBin();
  };

  const handleCloseAreYouSureRejectRequest = () => {
    dispatch(setIsInboxRejectRequestModal(false));
  };

  const handleConfirmAreYouSureRejectRequest = async () => {
    handleCloseAreYouSureRejectRequest();
    refreshState();
    toastSuccess(
      inboxToRejectRequest.length > 1
        ? t("toastRejectRequestsSuccess")
        : t("toastRejectRequestSuccess"),
    );
    dispatch(setInboxToRejectRequest([]));
  };

  const handleCloseMoveModal = () => {
    dispatch(setIsMovingItemsModal(false));
    dispatch(setDestinationFolder(null));
    dispatch(setCurrentContextFolder(null));
  };

  const handleOpenCreate = () => {
    setIsCreateModal(true);
  };

  const handleCloseCreate = () => {
    setIsCreateModal(false);
    dispatch(setCurrentContextFolder(null));
  };

  const handleCloseRenameModal = () => {
    dispatch(setRenameModalItem(""));
    dispatch(setCurrentContextFolder(null));
  };

  return (
    <>
      <Modal
        isShowed={isDeleteInboxItemsModal}
        onClose={handleCloseAreYouSureMoveToBin}
      >
        <AreYouSureModal
          onClose={handleCloseAreYouSureMoveToBin}
          onSubmit={handleConfirmAreYouSureMoveToBin}
          info={t("areYouSureMoveToBin")}
          addInfo={
            isSomeNeedToSign ? (
              <p className={styles.addInfo}>
                {t("areYouSureMoveToBinNeedSign")}
              </p>
            ) : (
              <></>
            )
          }
          confirmButtonTitle={t("areYouSureMoveToBinConfirmButtonTitle")}
        />
      </Modal>
      <Modal
        isShowed={isInboxRejectRequestModal}
        onClose={handleCloseAreYouSureRejectRequest}
      >
        <RejectSigningModal
          onClose={handleCloseAreYouSureRejectRequest}
          onSubmit={handleConfirmAreYouSureRejectRequest}
        />
      </Modal>
      <Modal isShowed={isMovingItemsModal}>
        <MovingItemsModal
          onClose={handleCloseMoveModal}
          onCreate={handleOpenCreate}
          refreshState={refreshState}
          isInbox
        />
      </Modal>
      <Modal
        styleWrap={{ zIndex: 110 }}
        isShowed={isCreateModal}
        onClose={handleCloseCreate}
      >
        <CreateFolderModal onClose={handleCloseCreate} isMoving />
      </Modal>
      <Modal isShowed={!!renameModalItem}>
        <RenameModal
          onClose={handleCloseRenameModal}
          inboxOldName={inboxDocs.find((el) => el.id === renameModalItem)?.name}
          refreshSentState={refreshState}
        />
      </Modal>
    </>
  );
};
