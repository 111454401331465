import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import OutsideClickHandler from "react-outside-click-handler";
import { useParams, useNavigate } from "react-router-dom";

import {
  useAppDispatch,
  useAppSelector,
  dashboard,
  signings,
  folders,
  signatures,
} from "store";

import {
  setIsDetailedViewMenu,
  setInboxDoc,
  setIsInboxRejectRequestModal,
  setIsMovingItemsModal,
} from "store/signings";
import {
  setRenameModalItem,
  setDestinationFolder,
  setCurrentContextFolder,
} from "store/folders";
import { getInboxDoc } from "store/signings/thunks";
import { getSignatures } from "store/signatures/thunks";

import { Button, Icon, Modal } from "components/UI";
import {
  DocIDCopy,
  RenameModal,
  RejectSigningModal,
  MovingItemsModal,
  CreateFolderModal,
} from "components";

import { PATHES } from "constants/pathes";
import { useActions } from "hooks";
import { SIGNING_STATUSES } from "types";
import { cs, palette, toastSuccess } from "utils";

import styles from "./styles.module.scss";

export const RecipientSigningHeader = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("RecipientSigningHeader");
  const { dateFormat, timeFormat } = useAppSelector(dashboard);
  const {
    isDetailedViewMenu,
    inboxDoc,
    isInboxRejectRequestModal,
    isMovingItemsModal,
  } = useAppSelector(signings);
  const { renameModalItem } = useAppSelector(folders);
  const { isDeleted, isCreated, isUpdated } = useAppSelector(signatures);

  const { id, name, receivedAt, sendBy, status } = inboxDoc || {};
  const { handleDeleteInbox, handleRejectRequest, handleRenameInboxDocument } =
    useActions(id);
  const [isCreateModal, setIsCreateModal] = useState<boolean>(false);

  const { inboxDocId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (inboxDocId) {
      dispatch(getInboxDoc(inboxDocId));
    }
  }, [dispatch, inboxDocId]);

  useEffect(() => {
    dispatch(getSignatures());
  }, [dispatch, isDeleted, isCreated, isUpdated]);

  const handleCloseDocument = async () => {
    localStorage.removeItem("instantJSON");
    dispatch(setInboxDoc(null));
    navigate(PATHES.INBOX);
  };

  const handleOpenMenu = () => {
    dispatch(setIsDetailedViewMenu(true));
    // setIsMenu(true);
  };

  const handleCloseMenu = () => {
    dispatch(setIsDetailedViewMenu(false));
    // setIsMenu(false);
  };

  const handleDeleteDocument = () => {
    if (id) {
      handleDeleteInbox();
      handleCloseMenu();
    }
  };

  const handleRejectDocument = () => {
    if (id) {
      handleRejectRequest();
      handleCloseMenu();
    }
  };

  const handleCloseRenameModal = () => {
    dispatch(setRenameModalItem(""));
  };

  const handleSubmitReject = () => {
    dispatch(setIsInboxRejectRequestModal(false));
    handleCloseDocument();
    toastSuccess(t("toastRejectSuccess"));
  };

  const handleCloseMoveModal = () => {
    dispatch(setIsMovingItemsModal(false));
    dispatch(setDestinationFolder(null));
    dispatch(setCurrentContextFolder(null));
  };

  const handleOpenCreate = () => {
    setIsCreateModal(true);
  };

  const handleCloseCreate = () => {
    setIsCreateModal(false);
    dispatch(setCurrentContextFolder(null));
  };

  return (
    <nav className={styles.RecipientSigningHeader}>
      <Modal isShowed={!!renameModalItem}>
        <RenameModal onClose={handleCloseRenameModal} isDetailedView />
      </Modal>
      <Modal isShowed={isInboxRejectRequestModal}>
        <RejectSigningModal
          onClose={handleCloseRenameModal}
          onSubmit={handleSubmitReject}
        />
      </Modal>
      <Modal isShowed={isMovingItemsModal}>
        <MovingItemsModal
          onClose={handleCloseMoveModal}
          onCreate={handleOpenCreate}
          isInbox
        />
      </Modal>
      <Modal
        styleWrap={{ zIndex: 110 }}
        isShowed={isCreateModal}
        onClose={handleCloseCreate}
      >
        <CreateFolderModal onClose={handleCloseCreate} isMoving />
      </Modal>
      <div className={styles.inner}>
        <Button
          title={t("backButtonTitle")}
          size="sm"
          variant="textBlack"
          onClick={handleCloseDocument}
          iconStart="chevron-left-empty"
          iconStartType="stroke"
          className={styles.backButton}
        />
        <div className={styles.info}>
          <h2
            className={styles.title}
            onClick={() => handleRenameInboxDocument()}
          >
            {name}{" "}
            {status === "COMPLETED" && (
              <Icon
                name="edit"
                className={styles.editNameIcon}
                color={palette.grey90}
                size={16}
              />
            )}
          </h2>
          <div className={styles.docInfo}>
            <p className={styles.date}>
              {status === "COMPLETED" ? t("createdOn") : t("received")}{" "}
              {dayjs(receivedAt).format(`${dateFormat}, ${timeFormat}`)}
            </p>
            {status !== "COMPLETED" && (
              <p className={styles.sender}>
                {t("by")} {sendBy}
              </p>
            )}
            <div className={styles.id}>
              {t("id")}: {id && <DocIDCopy id={id} size="sm" />}
            </div>
          </div>
        </div>
        <div className={styles.actionButtons}>
          {status && (
            <div className={cs([styles.status, styles[status]])}>
              {SIGNING_STATUSES[status]}
            </div>
          )}
          <OutsideClickHandler onOutsideClick={handleCloseMenu}>
            <div onClick={handleOpenMenu} className={styles.actionButton}>
              <Icon name="three-dots-horizontal" className={styles.action} />
              {isDetailedViewMenu && (
                <Button
                  title={
                    status === "NEED_TO_SIGN"
                      ? t("rejectButtonTitle")
                      : t("deleteButtonTitle")
                  }
                  size="md"
                  variant="secondary"
                  onClick={
                    status === "NEED_TO_SIGN"
                      ? handleRejectDocument
                      : handleDeleteDocument
                  }
                  iconStart="close-in-circle"
                  iconStartType="stroke"
                  className={styles.menuButton}
                />
              )}
            </div>
          </OutsideClickHandler>
        </div>
      </div>
    </nav>
  );
};
