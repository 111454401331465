import { useState, FC } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector, signings } from "store";

import { Icon } from "components/UI";

import { cs } from "utils";

import styles from "./styles.module.scss";

export const RejectionWidget: FC = () => {
  const { t } = useTranslation("RequestSigning", {
    keyPrefix: "RejectionWidget",
  });
  const { inboxDoc } = useAppSelector(signings);
  const [isMinimized, setIsMinimized] = useState<boolean>(false);
  const [isResizer, setIsResizer] = useState<boolean>(false);

  if (inboxDoc?.recipients?.length === 0) {
    return <></>;
  }

  return (
    <nav
      className={cs([styles.RejectionWidget, isMinimized && styles.minimized])}
    >
      <div
        className={cs([styles.resizer, isResizer && styles.visible])}
        onClick={() => setIsMinimized((prevState) => !prevState)}
        onMouseEnter={() => setIsResizer(true)}
        onMouseLeave={() => setIsResizer(false)}
      >
        <Icon
          name="chevron-left-empty"
          size={20}
          className={cs([styles.arrowIcon, isMinimized && styles.rotated])}
        />
      </div>
      <h2 className={styles.title}>{t("title")}:</h2>
      <div className={styles.reasonText}>
        Explanation text and description which user left
      </div>
    </nav>
  );
};
